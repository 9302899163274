.img-contain {
  object-fit: contain;
}

.app-title {
  margin: 8px 0;

  font-family: $fontFamilyMuseo700;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;

  @media (min-width: $tablet) {
    font-size: 1.5rem;
  }
}

.menuButton {
  border-radius: 50px;
  // border: solid 1px $black;
  background-color: rgba(0, 0, 0, 0.08);
}