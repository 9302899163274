@import 'variables';

@mixin selected-background($multi: 1) {
  background: repeating-linear-gradient(120deg,
      white,
      white $gutterStripes*$multi,
      $lightGrey $gutterStripes*$multi,
      $lightGrey $gutterStripes*$multi*2);
}

@mixin horizontal-flex {
  display: flex;
  flex-flow: row nowrap;
}

@mixin left-right-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

@mixin list-item-container {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px 0px;
}
