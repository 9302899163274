$duration: 4s;

@mixin shineMixin($shineTextColor: $tcsRed, $shineColor: #ffc552) {
  background: $shineTextColor -webkit-gradient(linear, left top, right top,
      from($shineTextColor), to($shineTextColor),
      color-stop(0.5, $shineColor)) 0 0 no-repeat;

  background-size: 150px;
  color: rgba(255, 255, 255, 0);
  background-clip: text;
  animation-name: shine;
  animation-duration: $duration;
  animation-iteration-count: infinite;
}

@keyframes shine {
  0% {
    background-position: -500px;
  }

  100% {
    background-position: 500px;
  }
}


.shine {
  @include shineMixin($tcsRed, #ffc552);

  &.red {
    @include shineMixin($tcsRed, #ffc552);
  }

  &.green {
    @include shineMixin($tcsGreenDark, $tcsGreenLight);
  }

  &.blue {
    @include shineMixin($tcsBlueDark, $tcsBlueGray1);
  }
}