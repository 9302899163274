.fuel-selector {
  @media (min-width: $desktop) {
    position: absolute;
    bottom: 55px + 16px; //MUI bottom nav height + padding
    left: 50%;
    width: $desktopFooterWidth;
    transform: translateX(-50%);

    border-radius: $roundedCornerRadius;
  }

  background: white;
}