@import '/src/scss/variables';

.imageZoom {
  transition: transform .2s;
  overflow: hidden;
}

.imageZoom:hover {
  transform: scale(1.1);
}

.pointer {
  cursor: pointer;
}

.contentPrizeMagnifier {
  position: absolute;
  right: 0px;
  top: -10px;
  background: white;
  width: 30px;
  height: 30px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  svg {
    height: 30px;
    width: 30px;
  }
}

// ----

.contestsRules {
  svg {
    color: $black;
  }
}

.capitalize:first-letter {
  text-transform: capitalize;
}

.contestsUserTokenBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: center;
  row-gap: 10px;
  border: 1px $tcsYellow solid;
  padding: 10px 15px;
  border-radius: 6px;
  background-color: #ffeb00;

  button {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin: 0;
    padding: 6px 10px;
  }

  >div {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .contestsUserTokensAttribution p.MuiTypography-body1 {
    margin: 0;
  }
}

.contestsUserTokens,
.contestsUserTokensAttribution {
  min-width: 200px;
  width: 50%;
  flex: 1 1 auto;
}

.contestDialog .contestBody.contestBodyDialog {
  display: flex;
  align-content: normal;
  gap: 10px;
  flex-wrap: nowrap;
  flex-direction: column;

  img {
    max-height: 40vh;
  }

  .contestPrizeActionsInfo.disabled {
    &.disabled {
      color: $darkGrey;
    }
  }

  .contestPrizeContent {
    max-height: 40vh;

    .contestPrizeContentDetail {
      height: auto;
    }

    .contestPrizeDescription {
      overflow-y: scroll;
      max-height: 10vh;
    }
  }
}

.inactiveContest .contestBody .contestPrizeActions .contestPrizeActionsInfo div {
  color: $darkGrey;

  svg {
    stroke: $darkGrey;
    fill: none;
  }
}

.contestHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: $tcsYellow;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid $darkGrey;

  &>div {
    flex: 2;
  }

  &>h5 {
    flex: 1;
  }
}


.contestPrizeCard {
  width: calc((100% / 3) - 7px);
  min-width: 280px;
}

/*
    &>div {
      flex: 1 0 calc(25% - 10px);
    }*/
// .contestPrizeContent {
//   position: relative;

//   .contestPrizeDescription {
//     overflow-y: scroll;
//     max-height: 200px;
//   }

//   .contestPrizeContentDetail {
//     display: flex;
//     gap: 10px;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     height: 70px;

//     .contestPrizeSponsor {
//       display: flex;
//       flex-direction: column;
//       align-content: normal;
//       align-self: center;
//       align-items: center;

//       img {
//         max-width: 75px;
//       }

//       span {
//         align-self: center;
//       }
//     }
//   }
// }

.contestPrizeActions {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .contestPrizeActionsInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    color: black;
    margin-bottom: 10px;

    &.disabled {
      color: $darkGrey;
    }

    &.contestPrizeActionsInfoWinner {
      background-color: #ffeb02;
      padding: 5px;

      .contestPrizeActionsInfoWinnerRowDetail {
        flex-direction: column;
        color: black;

        svg {
          font-size: 1em;
        }
      }
    }

    div {
      display: flex;
      align-items: center;
    }
  }

  :nth-child(n+3) {
    grid-column-end: span 2;
    margin-left: 0;
  }
}