.contest {
  .header {
    background-color: $tcsYellow;

    h1 {
      margin-top: 0px;
    }

    .img-contained {
      max-width: 100%;
    }

    .inner {
      padding: 4rem;
      padding-top: 0px;
    }
  }

  .upcoming-rewards {
    .prizes {
      @include horizontal-flex;
      flex-wrap: wrap;
      gap: 1rem;

      .prize {
        flex: 1 0 calc(50% - 0.5rem);

        img {
          border-radius: 1rem;
          width: 100%;
        }
      }
    }
  }

  &.won-prizes {
    height: 100%;
  }
}

.upcoming-prizes {
  .MuiImageListItem-img.prizeImg {
    object-fit: contain;
    height: 164px;
  }

  .MuiImageListItem-img.prizeImg-cover {
    object-fit: cover;
    height: 164px;
  }
}

.how-it-works {
  .steps {
    counter-reset: steps;

    .number {
      background-color: $tcsYellow;
      border-radius: 25px;
      font-size: 1.5rem;
      text-align: center;
      font-weight: bold;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      padding-top: 1px
    }

    .step {
      .icon-text {
        @include horizontal-flex;
        gap: 2rem;
        margin: 1rem 0px;

        .icon {
          .MuiBadge-badge {
            top: auto;
            right: -4px;
            bottom: -22px;
            font-size: 0.8rem;
            font-weight: bold;
          }


          svg {
            width: 30px;
            height: 30px;
            stroke: black;
          }
        }
      }
    }
  }
}