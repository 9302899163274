.center-on-me {
    position: absolute;
    bottom: 120px;
    @media (max-width: $tablet) {
        bottom: 135px;
    }
    right: 10px;
}

.gmapcontainer {
    user-select: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
    border-radius: 2px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    .locatemelogo {
        fill: #666;
    }

    &:focus,
    &:hover {
        .locatemelogo {
            fill: black;
        }
    }
}