$breakpoint-desktop: 768px;

.simplePage {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  background: $tcsBeige1;
  font-family: $fontFamilyMuseo300;

  .container {
    max-width: 1024px;
    align-self: center;
    background-color: white;
    box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.15);
    height: auto;
    overflow: auto;
    min-height: 100%;

    @media (min-width: $desktop) {
      padding-bottom: calc(3rem + 10px);
      /* approximate height of the bottom bar in absolute position */
    }

    .content {
      margin-bottom: 20px;

      /* All contents have some margins but the container itself is still full width.
       Full width children can override the default margins and paddings */
      >*:not(.no-margin) {
        margin-left: 8px;
        margin-right: 8px;

        @media (min-width: $tablet) {
          margin-left: 24px;
          margin-right: 24px;
        }
      }




      .simplePageBanner {
        height: auto;
        min-height: 150px;
        object-fit: cover;
        width: 100%;
        margin: 0 0 20px 0;
        box-shadow: 0 0 10px black inset;
        padding-top: 0;
      }

      .MuiTypography-caption {
        font-weight: 100;
        font-size: 12px;
        line-height: 15px;
        font-style: italic;
        color: gray;
      }

      .profile-thanks {
        padding-top: 0px;

        .icon-text .icon {
          margin-right: 20px;
          width: 40px;
        }

        .buttonBloc {
          display: flex;
          justify-content: center;

          button {
            padding: 12px 60px;
          }
        }

        .customSVGIcon {
          width: 1.6rem;
        }

        .profileThanksBloc {
          background: $lightGrey;
          border-radius: 6px;
          padding: 10px 20px;
          box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.12);

          .customSVGIcon {
            width: 2rem;
          }

          h2 {
            margin-top: 0px;
          }
        }
      }
    }
  }
}