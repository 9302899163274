.drawer .MuiDrawer-paper {
  margin: auto;

  max-width: $desktop;
  min-height: 40vh;
  max-height: 70vh;

  @media (min-width: $desktop) {
    max-width: $desktop;
  }

  border-top-left-radius: $roundedCornerRadius;
  border-top-right-radius: $roundedCornerRadius;
}