$blue: blue;
$black: black;
$lighterGrey:#F9F9F9;
$lightGrey: #F6F6f6;
$darkGrey: #CCC;
$disabledGrey: #999;

$gutterStripes:5px;
$violet:#8218F8;

$tcsGreenLight: #aac800;
$tcsGreenDark: #69a023;
$tcsYellow: #ffeb00;
$tcsRed: #cd0046;
$tcsBlueGray1: #e9f1f5;
$tcsBlueGray2: #d5e2e8;
$tcsBlueGray3: #bccdd6;
$tcsBlueLight: #50afe1;
$tcsBlueDark: #005aa0;
$tcsBeige1: #f5f1e9;
$tcsBeige2: #e8e2d5;
$tcsBeige3: #d6cebc;

$error:$tcsRed;
$warning: #ed6c02;
$success: $tcsGreenDark;
$gray-700:#616161;

$tablet: 600px;
$desktop: 768px;
$desktopFooterWidth: 630px;

$roundedCornerRadius: 8px;

$fontFamilyMuseo300: "Museo Sans 300", sans-serif;
$fontFamilyMuseo700: "Museo Sans 700", sans-serif;
$fontFamilyMuseoSlab: "Museo Slab 700", sans-serif;