$unselectedTextColor: $black;
$unselectedBackgroundColor: $tcsBeige1;
$selectedTextColor: $tcsBlueDark;
$selectedBackgroundColor: $tcsBeige1;

.bottomNav {
  background: $unselectedBackgroundColor;

  /* we need this because we can't add padding to the MUI nav bar */
  padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

  @media (min-width: $desktop) {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: $desktopFooterWidth;
    transform: translateX(-50%);

    border-top-left-radius: $roundedCornerRadius;
    border-top-right-radius: $roundedCornerRadius;

    border-top: 1px solid rgba($color: #000, $alpha: 0.1);
    border-right: 1px solid rgba($color: #000, $alpha: 0.1);
    border-left: 1px solid rgba($color: #000, $alpha: 0.1);
  }

  border-top: 1px solid rgba($color: #000, $alpha: 0.1);


  .bottomNav--nav {
    margin: auto;
    background: none;

    button {

      justify-content: space-evenly;

      &.bottomNavContestIcon {
        svg {
          margin-top: 2px;
          width: 26px;
          height: 26px;
          fill: black;
          stroke-width: 0;
        }
      }
      svg {
        fill: $unselectedTextColor;
        width: 24px;
        height: 24px;
      }

      span {
        font-size: 12px;
        font-weight: 'bold';
        color: $unselectedTextColor;
      }


      &.Mui-selected {
        background: $selectedBackgroundColor;

        span {
          color: $selectedTextColor;
          font-weight: bold;
          font-family: $fontFamilyMuseo700;
        }

        svg {
          fill: $selectedTextColor;
          stroke: $selectedTextColor;
          .st0, .st1 {
            stroke: $selectedTextColor;
          }
        }
      }
    }
  }
}
