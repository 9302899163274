@font-face {
  font-family: 'Museo Sans 300';
  src: url('fonts/MuseoSans-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans 700';
  src: url('fonts/MuseoSans-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Slab 700';
  src: url('fonts/MuseoSlab-700.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $fontFamilyMuseo300;
  height: 100%;
  overscroll-behavior: contain;
}

html {
  min-height: 100%;
}

body {
  //noinspection CssInvalidPropertyValue,CssOverwrittenProperties
  height: 100dvh;
  /* dynamic viewport height, not yet supported in Chromium based browsers */
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  font-family: $fontFamilyMuseo700;
}

#root,
.App,
.main {
  height: 100%;
}

.topAdminBar {
  background-color: white;
}

.google-map {
  display: none;
  height: 100%;
  width: 100%;
  flex-direction: column;

  &.active {
    display: flex;
  }

  .google-map-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }


  //Keep the same value between these variables and the ones in GMapMarker.tsx
  $pinLegWidth: 18px;
  $pinLegHeight: 14px;

  .marker {
    cursor: pointer;
    position: absolute;
    transform: translate(-50%, -100%);

    .marker-inner {
      position: relative;

      .marker-info {
        transform: translateX(calc(-50% + $pinLegWidth / 2));
        position: absolute;
        bottom: $pinLegHeight;
      }
    }
  }

  .marker-active {
    z-index: 100;
  }

  .cluster {
    cursor: pointer;
    position: absolute;
    transform: translate(-50%, -50%);

    .cluster-inner {
      position: relative;
    }
  }

  $markerSize: 20px;
  $halfMarkerSize: calc($markerSize / 2);

  .user-marker {
    z-index: 9999;
    cursor: pointer;
    position: absolute;

    border: 3px solid white;
    border-radius: $halfMarkerSize;
    width: $markerSize;
    height: $markerSize;
    box-shadow: rgba(0, 0, 0, 0.8) 0 1px 4px -1px;

    top: -$halfMarkerSize;
    left: -$halfMarkerSize;
    background-color: $tcsBlueDark;

    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
  }
}

.profile-thanks {
  .icon-text {
    @include list-item-container;
    margin-bottom: 1rem;

    .icon {
      flex-shrink: 0;
    }

    .text {
      flex-grow: 1;
    }
  }
}

.brand-logo {
  max-height: 30px;
  width: auto;
}

// dialog styling
.MuiDialog-scrollPaper {
  .MuiDialog-paperScrollPaper {
    max-width: 380px;
  }
}

.MuiButtonBase-root.fiability-chip {
  line-height: 1rem;
  padding-left: 0.25rem;
  margin-left: -0.25rem;
}

.dialog {
  .MuiDialogTitle-root {
    padding: 1.5rem 1.5rem 1rem;
  }

  .MuiDialogContent-root {
    padding-bottom: 0;

    &>p,
    &>ul {
      margin-top: 0;
      margin-bottom: 1rem;
      padding: 0;
    }
  }

  .MuiTypography-h6,
  .MuiTypography-h5 {
    font-weight: 700;
    font-family: $fontFamilyMuseo300;
  }

  .MuiTypography-h5 {
    margin-bottom: 1rem;
  }

  .MuiListItemButton-root.Mui-selected,
  .MuiListItemButton-root.Mui-selected:hover {
    background-color: rgba($tcsBlueDark, 0.08);
  }

  a {
    text-decoration: none;
    color: $tcsBlueDark;
  }

  .addFuelListItem {
    border-bottom: 1px solid $tcsBlueGray2;

    &:first-child {
      border-top: 1px solid $tcsBlueGray2;
    }

    &.disabledFuel {
      color: $disabledGrey;
    }

    .selectedFuelIcon {
      height: 20px;
    }
  }

  .MuiDialogActions-root {
    align-items: flex-end;
    padding: 0 1.5rem 1.5rem;
  }

  .dialog-title {
    @include left-right-container;
    align-items: center;

    .dialog-title-right {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      flex-shrink: 0;
    }
  }

  .icon-text {
    @include list-item-container;
    margin-bottom: 1rem;

    .icon {
      flex-shrink: 0;
    }

    .text {
      flex-grow: 1;
      font-family: "Museo Sans 300", "sans-serif";
    }
  }

  .list-item {

    @include list-item-container;
    padding-left: 0;
    padding-right: 0;


    .list-item-avatar,
    .list-item-new,
    .list-item-points {
      flex-shrink: 0;
    }

    .list-item-points {
      span {
        padding-left: 10px;
        padding-right: 10px;
      }

      div {
        height: 25px;
      }
    }

    .list-item-avatar {
      .MuiAvatar-root {
        width: 35px;
        height: 35px;
        box-shadow: -2px 3px 7px -1px rgba(0, 0, 0, 0.34);
      }
    }

    .list-item-text {
      flex-grow: 1;
      font-size: 0.72rem;
      line-height: 1.2;
      font-family: $fontFamilyMuseo300;
    }
  }

  .buttons-incrementer {
    user-select: none;
    -webkit-touch-callout: none;
  }

  &.geo-error {
    ol {
      padding-inline-start: 1rem;

      li {
        margin-bottom: 0.5rem;
      }
    }

    .geo-error-image {
      max-width: 100%;
    }
  }

  &.feedback {
    .MuiDialogContent-root>div {

      margin-bottom: 1rem;
    }
  }

  &.history {
    .title {
      font-weight: 700;
    }

    .info-text {
      font-size: 0.8rem;
      font-weight: normal;
    }

    .history-list {
      .history-list-item {
        display: flex;
        align-items: center;
        justify-content: stretch;
        gap: 0.2rem;
        padding: 0.3rem;
        background-color: $lightGrey;
        border-radius: 0.2rem;
        margin-bottom: 0.2rem;

        .price-time {
          flex-grow: 1;
          font-size: 0.68rem;

          .price {
            font-weight: 700;
          }

          .time {
            font-weight: normal;
          }
        }

        .display-name {
          flex-grow: 1;
          font-size: 0.68rem;
          font-weight: 700;
          text-align: right;
        }

        .avatar {
          flex-shrink: 0;

          .MuiAvatar-root {
            width: 28px;
            height: 28px;

          }
        }
      }
      .winner-item {
        background-color: $tcsGreenLight;
      }
      .winner-list-item {
        .winner-display-name {
          flex-grow: 1;
          font-size: 0.68rem;
          font-weight: 700;
          display: flex;
          align-items: center;
        }
        .winner-text {
          flex-grow: 1;
          font-size: 0.68rem;
        }
        .winner-avatar {
          flex-shrink: 0;

          .MuiAvatar-root {
            width: 28px;
            height: 28px;

          }
        }
      }
    }
  }
}

.customSVGIcon {
  width: 1.2rem;

  stroke: black;
  fill: none;

  &.whiteStroke {
    stroke: white;
  }

  &.size20 {
    width: 20px;
    height: 20px;
  }

  &.size25 {
    width: 25px;
    height: 25px;
  }

  &.size32 {
    width: 32px;
    height: 32px;
  }

  &.size48 {
    width: 48px;
    height: 48px;
  }

  &.size64 {
    width: 64px;
    height: 64px;
  }
}

.profile {

  .MuiBadge-badge {
    right: -0.4rem;
    background-color: #005aa0;
    color: white;
  }

  .avatar-username {
    margin: 1rem 0 2rem 0;
    display: flex;
    gap: 1rem;
    align-items: center;
    background: #F5F5F5;
    padding-left: 2.5rem;
    border-radius: 5px;

    .avatar {
      flex: 0.5;
    }

    .username {
      display: flex;
      background-color: white;
      flex: 2;
      margin-right: 2.5rem;

      button {
        flex-grow: 1;
        display: flex;
        justify-content: left;
        padding: 0 10px 0 1rem;

        .MuiBadge-root {
          flex: 1;
          color: black;
        }
      }

      .MuiBadge-badge {
        top: -4px;
      }
    }
  }

  .checkboxes {
    margin-bottom: 2rem;

    .checkbox {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }
  }

  .tcs-account {
    @include left-right-container;
    margin: 1rem;
    border: 3px solid black;
    padding: 1rem;

    img {
      object-fit: contain;
      height: auto;
      max-width: 48px;
    }
  }

  .actions {
    padding: 0;
    margin: 0;

    .MuiListItem-root {
      padding-left: 0;
      padding-right: 0;

      .MuiButtonBase-root {
        border-radius: 5px;
        border: 0;
        background-color: #F5F5F5;
        padding-left: 2.5em;

        &:hover {
          background-color: #F2F2F2;
        }

        .MuiTypography-body1 {
          font-weight: bold;
        }
      }
    }

    .MuiDivider-root {
      border: none;
    }

    .see-won-prizes {
      .MuiButtonBase-root {
        background-color: $tcsGreenLight;

        &:hover {
          background-color: $tcsGreenDark;
        }
      }
    }
  }
}

.avatar-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

.form {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(20px);
  }

  85% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.MuiToolbar-root {
  .MuiButton-root {
    font-size: 0.7rem;
  }
}

.lotterySVG {
  fill: black;
  stroke-width: 0;

  &.stroke1 {
    stroke-width: 1;
  }

  &.white {
    fill: white
  }

  &.blue {
    fill:$tcsBlueDark;
  }

  &.red {
    fill:$tcsRed;
  }
}

.winners-dialog-header-summary {
  background-color: $tcsBeige2;
  width: 100%;
  padding: 0.3rem;
  border-radius: 0.2rem;
}

.winners-dialog-header-congratulation{
  background-color: $tcsGreenLight;
  margin-top: 1.5rem;
  width: 100%;
  padding: 0.3rem;
  border-radius: 0.2rem;
}

.maplibregl-ctrl-group {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  width: 40px;
  height: 80px;
  @media (max-width: $tablet) {
    margin-bottom: 0 !important;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px !important;
  border-radius: 2px !important;
  .maplibregl-ctrl-zoom-out,
  .maplibregl-ctrl-zoom-in {
    width: 40px !important;
    height: 40px !important;
    .maplibregl-ctrl-icon {
      filter: invert(40%) sepia(1%) saturate(2310%) hue-rotate(22deg) brightness(94%) contrast(84%);
    }
  }
}

.maplibregl-ctrl-attrib-button {
  filter: invert(40%) sepia(1%) saturate(2310%) hue-rotate(22deg) brightness(94%) contrast(84%);
}