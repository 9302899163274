.fuel-list-item {
  @include list-item-container;

  border-radius: 8px;
  background: $tcsBlueGray1;

  &.selected-fuel,
  .selected-fuel {
    background: $tcsBeige1;
  }
}
